a {
  color: black;
}

body {
  background-color: #fef8fb;
  font-family: 'Libre Baskerville', serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section {
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: calc(25px + 2vmin);
}

.icon {
  padding: 0px 20px;
}

p {
  font-size: medium;
}
